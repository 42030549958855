<template>
  <b-overlay :show="loading">
    <div class="container">
      <div class="d-flex flex-column h-full w-100 h-50">
        <div style="width: 200px">
          <img class="mt-5" src="@/assets/mda_1628620680.png" style="width: 70px;"/>
          <h6 class="mt-2 text-align-left" style="font-weight: bold;">Ghana Tourism Authority</h6>
        </div>

        <!-- For registration payments -->
        <div style="background-color: #f8f9fa; border-width: 1px; border-color: #6c757d" class="bg-gray w-100 h-50 pt-5" v-if="!loading && registrationDetails && registrationID">
          <p class="mt-4 text-center" >
            Hello 
            <strong>{{ getValueFromSource('host.display_value') }}</strong>, <br />Please pay an amount of <strong>GH¢{{formatMoney(getValueFromSource('fees.totalLicenseFee'))}}</strong> to receive your provisional license for your Short Term Rental Facility.
            
            <br />
            <br />
            <span style="color: orange; font-size: 15px;">Your payment covers the following listing.</span>
            <b-table hover :fields="fields" :items="getValueFromSource('listings')" />

            <br /><span style="color: gray; font-size: 13px;">Please note! there will be an additional 1.95% service fee added to the transaction amount.</span>
          </p>

          <a :href="getValueFromSource('payment_link', '#')" class="btn-pay">Pay Now</a>
        </div>

        <div style="background-color: #f8f9fa; border-width: 1px; border-color: #6c757d" class="bg-gray w-100 h-50 pt-5" v-if="!loading && registrationDetails === undefined">
          <p class="mt-4 text-center" style="color: tomato;">There was an issue with your credentials. Please report if issue persist.</p>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { get } from "lodash";
import { BOverlay, BTable } from "bootstrap-vue";

import axios from "../services/axios";

export default {
  components: {
    BOverlay,
    BTable
  },
  data(){
    return {
      loading: false,
      registrationDetails: undefined,
      fields: ['Region', 'Location', 'GP_Address', 'Provisional_License_Fee'],
      listingColumns: [
        {

        }
      ]
    }
  },
  computed: {
    registrationID() {
      const { 
        registration_id 
      } = this.$route.params;

      return registration_id
    },
  },
  created() {
    if ( this.registrationID ){
      this.fetchRegistrationDetails()
    }
  },
  methods: {
    async fetchRegistrationDetails(){
      try {
        this.loading = true;

        const registrationDetails = await axios.get(`/gta-single-window/module2/short-term-rental/${this.registrationID}/registration`, {});
        this.registrationDetails = registrationDetails.data.data;
      } catch(error){
        this.registrationDetails = undefined;
        const error_message = get(error, 'response.data.message') || error.message;

        this.$swal({
          title: "An Error Occurred!",
          text: error_message || "An unexpected error occurred please contact support if issue persist.",
          icon: "danger"
        });
      } finally {
        this.loading = false;
      }
    },
    formatMoney(
      number,
      decPlaces,
      decSep,
      thouSep
    ) {
      (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
        (decSep = typeof decSep === "undefined" ? "." : decSep);
      thouSep = typeof thouSep === "undefined" ? "," : thouSep;
      const sign = number < 0 ? "-" : "";
      const i = String(
        parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
      );

      let j = 0;
      j = (j = i.length) > 3 ? j % 3 : 0;

      return (
        sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        (decPlaces
          ? decSep +
            Math.abs(number - i)
              .toFixed(decPlaces)
              .slice(2)
          : "")
      );
    },
    getValueFromSource(path, defaultValue = ""){
      return get(this.registrationDetails, path, defaultValue)
    }
  }
}
</script>

<style>
.btn-pay {
  background-color: orange; 
  padding: 5px; 
  border-radius: 10px; 
  color: white; 
  width: 200px; 
  display: inline-block; 
  text-decoration: none;
  text-align: center;
}

.btn-pay:hover {
  background-color: rgb(194, 144, 7);
  color: white;
}

.disabled-btn-pay {
  background-color: gray;
}
.disabled-btn-pay:hover {
  background-color: gray;
  color: white;
  cursor: no-drop;
}
</style>